import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import LayoutCustom from "../components/LayoutCustom";
import Footer from "../components/Footer";
import SEO from "../components/SEO";

class SiteMap extends React.Component {

	// componentDidMount() {
	// 	const { location } = this.props;

    //     var host_website = location.host ? location.host : "";
    //     var new_online_doc_url;

    //     if (host_website === "localhost:8000" || host_website === "127.0.0.1:8000") {
    //         new_online_doc_url = "http://protoweb.appvity.com:8083/";
    //     } else if (host_website === "protoweb.appvity.com:8082") {
    //         new_online_doc_url = "http://protoweb.appvity.com:8083/";
    //     } else if (host_website === "vmweb:8082") {
    //         new_online_doc_url = "http://vmweb:8083/";
    //     } else if (host_website === "10.8.30.46:8082") {
    //         new_online_doc_url = "http://10.8.30.46:8083/";
    //     } else if (host_website === "10.9.9.104:8082") {
    //         new_online_doc_url = "http://10.9.9.104:8083/";
    //     } else {
    //         new_online_doc_url = "https://www.appvity.com:8083/";
    //     }

	// 	document.querySelector(".app-section-sitemap .app-sitemap-detail .app-list-general a.app-sitemap-online-doc").setAttribute("href", new_online_doc_url);
	// }

	render() {
		const { data } = this.props;
		const sitemaps = data.sitemaps.nodes;
		const sitemaps_vi = data.sitemaps_vi.nodes;

		return (
			<LayoutCustom>
                <SEO title="Site Map" description="Appvity" meta={[{ name: `keywords`, content: ["collaboration", "task management"] }]} pathname="/sitemap" />
				<div className="main-content-padding">
					<section className="app-section-sitemap" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
						<Container>
							<div className="app-title">
								<h2 className="app-data-en">Sitemap</h2>
								<h2 className="app-data-vi">Sơ Đồ Trang</h2>
							</div>
							<div className="app-sitemap-detail">
								<Row>
									{sitemaps.map((res) => (
										<Col className="app-data-en" xs={12} md={12} lg={2} key={res.id}>
											<ul className="app-list-general">
												<h5>{res.frontmatter.title}</h5>
												<li dangerouslySetInnerHTML={{ __html: res.html }} />
											</ul>
										</Col>
									))}
									{sitemaps_vi.map((res) => (
										<Col className="app-data-vi" xs={12} md={12} lg={2} key={res.id}>
											<ul className="app-list-general">
												<h5>{res.frontmatter.title}</h5>
												<li dangerouslySetInnerHTML={{ __html: res.html }} />
											</ul>
										</Col>
									))}
								</Row>
							</div>
						</Container>
						<div className="footer-custom">
							<Footer />
						</div>
					</section>
				</div>
			</LayoutCustom>
		)
	}
}

export default SiteMap;

export const query = graphql`
    query SiteMapPage {
        sitemaps: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "sitemaps" } } }
			sort: { fields: frontmatter___keyNumber, order: ASC }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
        sitemaps_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "sitemaps_vi" } } }
			sort: { fields: frontmatter___keyNumber, order: ASC }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
    }
`;